import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { assetPath, v as bvVar } from 'bv';
import { Button, RenderInBody } from 'bv-components';
import { format as formatPrice } from 'bv-helpers/price';
import { t } from 'bv-i18n';
import { useLockBodyScroll } from 'bv-hooks';
import { hasSportsbook } from 'global-config';
import { getWinnerDescription, replaceAssetPaths, replaceDynamicValues } from '../helpers';

const Animation = ({
  audio, onBetAgain, onClose, stopAudio, submitResponse: { betPlacementResult, selections },
}) => {
  const ref = useRef();
  const [animationVisible, setAnimationVisible] = useState(false);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const luckyDipAssets = bvVar('luckyDipAssets');
  const { formattedPrice } = betPlacementResult.singles[0];

  const handleClose = () => {
    setAnimationVisible(false);
    setTimeout(() => onClose(), 300);
  };

  const scrollableRef = useRef();

  useLockBodyScroll(scrollableRef);

  const handleVisibilityChange = () => {
    if (document.hidden && audio) {
      stopAudio();
    }
  };

  useEffect(() => {
    setAnimationCompleted(false);

    let animation;
    if (ref.current) {
      const initLottieAnimation = async () => {
        const { loadAnimation } = await import('lottie-web');

        animation = loadAnimation({
          container: ref.current,
          path: assetPath(luckyDipAssets.animationJson),
          renderer: 'svg',
          loop: false,
          autoplay: false,
        });

        animation.addEventListener('config_ready', () => {
          replaceAssetPaths(animation);
        });

        const enterFrame = () => {
          replaceDynamicValues({ animation, formattedPrice, selections });
          setAnimationVisible(true);
          animation.removeEventListener('enterFrame', enterFrame);
        };

        animation.addEventListener('enterFrame', enterFrame);

        animation.addEventListener('loaded_images', () => {
          setTimeout(() => {
            animation.play();
            audio?.play();
          }, 1000);
        });

        animation.addEventListener('complete', () => {
          setAnimationCompleted(true);
        });

        animation.addEventListener('data_failed', onClose);
      };

      initLottieAnimation();
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      if (animation) {
        animation.destroy();
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [betPlacementResult.id]);

  return (
    <RenderInBody className="ld-anim-modal-wrapper">
      <div
        data-testid="lucky-dip-animation"
        ref={scrollableRef}
        className={classnames('ld-anim-modal', {
          'is-loaded': animationVisible,
          'is-closed': (animationCompleted && !animationVisible),
        })}
      >
        <div
          className="ld-anim-modal__large-bg"
          style={{ backgroundImage: `url(${assetPath(luckyDipAssets.images['img_10.jpg'])})` }}
        />

        <div className="ld-anim-modal__content">
          <div className="ld-anim-modal__content-anim" ref={ref} />
          {animationCompleted && (
            <>
              <div className="ld-anim-modal__result-options">
                <span onClick={handleClose} className="ld-anim-modal__result-options-close bvs-link" />
              </div>
              <div className="ld-anim-modal__result">
                <p className="ld-anim-modal__result-title">{t('lucky_dip.animation.your_lucky_dip_is')}</p>
                <p className="ld-anim-modal__result-selection">{getWinnerDescription(selections)}</p>
                <p className="ld-anim-modal__result-price">{formatPrice(formattedPrice)}</p>
                <p className="ld-anim-modal__result-my-bets">
                  {t(`lucky_dip.animation.added_to_${hasSportsbook ? 'my_bets' : 'game_history'}`)}
                </p>
              </div>
              <Button onClick={onBetAgain} primary className="ld-anim-modal__result-button">
                {t('lucky_dip.animation.bet_again')}
              </Button>
            </>
          )}
        </div>
      </div>
    </RenderInBody>
  );
};

Animation.propTypes = {
  audio: PropTypes.instanceOf(HTMLAudioElement).isRequired,
  onBetAgain: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  stopAudio: PropTypes.func.isRequired,
  submitResponse: PropTypes.instanceOf(Object).isRequired,
};

export default Animation;
